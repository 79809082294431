@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-neutral-700 bg-gray-100 dark:bg-neutral-800 dark:text-neutral-50;
  }

  a, .link {
    @apply cursor-pointer font-medium;
  }

  button, input, textarea {
    @apply py-2 rounded text-[15px];
  }

  button {
    @apply px-4 font-medium shadow;
  }

  button[role="tab"] {
    @apply py-1.5 px-4 rounded-full shadow-none bg-gray-200/50 text-slate-500 hover:bg-gray-200 border data-[selected]:bg-green-50 data-[selected]:text-green-700 data-[selected]:border-green-700/20 data-[selected]:outline-none;
  }

  input, textarea {
    @apply px-4 border focus:outline-slate-300;
  }

  #menu-toggle:checked + #menu {
    display: block;
  }
}

@layer components {
  @import './styles/button.css';
}

.gradient-mask {
  position: relative;
}

.gradient-mask > div {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%),
              linear-gradient(to top, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 95%),
              linear-gradient(to left, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%),
              linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  mask-composite: intersect;
}

.section-divider {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid transparent;
  background-image: linear-gradient(to bottom, transparent calc(100% - 5px), #dedede 5px);
  height: 3px;
  margin: 16px 0;
}

.dark .section-divider {
  border-top-color: #2c2c2c;
  background-image: linear-gradient(to bottom, transparent calc(100% - 5px), #333 5px);
}