.btn-primary {
  @apply inline-block text-center text-white bg-neutral-950 shadow-[5px_5px_0_0_rgba(71,85,105,0.25)] hover:shadow-none transition-all font-semibold;
  @apply dark:bg-neutral-50 dark:text-neutral-950 dark:shadow-[5px_5px_0_0_rgba(255,255,255,0.25)];
}

.btn-secondary {
  @apply inline-block text-center text-neutral-950 border-2 border-neutral-950 shadow-[3px_3px_0_0_rgba(71,85,105,0.25)] hover:shadow-none transition-all;
  @apply dark:border-neutral-50 dark:text-neutral-50 dark:shadow-[5px_5px_0_0_rgba(255,255,255,0.25)];
}

.btn-sm {
  @apply py-1 px-3 text-xs rounded;
}

.btn-md {
  @apply py-2 px-6 rounded;
}

.btn-lg {
  @apply py-4 px-10 text-lg rounded;
}

button[role="switch"]::after {
  --tw-translate-x: -.75rem !important;
}

button[role="switch"][data-checked]::after {
  --tw-translate-x: .5rem !important;
}
